<template>
  <div class="table_section">
    <table class="table  table-hover">
      <thead>
        <th width="40%">Agent Name</th>
        <th>Orders</th>
        <th>Change</th>
        <th>Total Sales (<b>{{ currency }}</b>)</th>
        <th>Sub Total (<b>{{ currency }}</b>)</th>
        <th>Shipping Fee (<b>{{ currency }}</b>)</th>
        <th>Average Order Value</th>                    
      </thead>
      <tbody v-if="agentSales.length > 0">
        <tr v-for="agentSale in agentSales" :key="agentSale.name">
          <td>
            {{ agentSale.name }}
          </td>
          <td>
            {{ agentSale.total_order }}
          </td>
          <td>
            <span v-if="agentSale.change !='nochange'" :class="agentSale.change == 'increase' ? 'increase_change' : 'decrease_change'">
              <i :class="agentSale.change == 'increase' ? 'fa fa-arrow-up': 'fa fa-arrow-down'" style="font-size:12px"></i> &nbsp; {{agentSale.percentange}}%
            </span>
            <span v-else>NA</span>
          </td>
          
          <td>
            {{ formatPrice(agentSale.total_amount) }}
          </td>
          <td>
            {{ formatPrice(agentSale.sub_total_price) }}
          </td>
          <td>
            {{ formatPrice(agentSale.total_shipping_cost) }}
          </td>
          <td>
            {{ agentSale.order_average_price }}
          </td>                       
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" style="text-align:center;">No data</td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="7">
            <div id="pagination" v-if="totalPages > 1">
              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="pageChange" />
              <ul class="showItems">
                <li>Show Items:
                  <select @change="recordChange" v-model="recordsPerPage">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="40">40</option>
                    <option :value="50">50</option>
                  </select>
                </li>
    
              </ul>
            </div>
          </td>
        </tr>
            
      </tfoot>
    </table>
  </div>
</template>
  <script>
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  export default {
      props: ['agentSales','loading','currency','totalPages', 'page','dataperPage'],
      mixins: [ReusableFunctionMixins],
      components: {
            Pagination,
          },
          data() {
                return {
                    recordsPerPage:10
                }
            },
            mounted:async function (){
                this.recordsPerPage = this.dataperPage
            },
            methods: {
                recordChange(){
                    this.$emit('record-changed', this.recordsPerPage)
                },
                pageChange(page){
                    this.$emit('page-changed', page)
                }
                
            },
  }
  </script>