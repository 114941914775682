<template>
  <div class="table_section">
    <table class="table  table-hover">
      <thead>
        <th  style="padding-left: 9% !important;" width="40%">Product</th>
        <th>Orders</th>
        <th>Order Sales (<b>{{ currency }}</b>)</th>
        <th>Items Sold</th>
        <th>Item Sales (<b>{{ currency }}</b>)</th>
                                
      </thead>
      <tbody v-if="productSales.length > 0">
        <tr v-for="productSale in productSales" :key="productSale.name">
          <td>
            <span v-if="productSale.image">
              <img  style="border-radius:3px;vertical-align: middle;" width="32" height="32" :src="productSale.image" alt=""> &nbsp;
            </span>
            <span v-else>
              <img style="border-radius:3px;vertical-align: middle;" width="32" height="32" src="../../../../../public/img/no_images.jpg" alt="">
                                      &nbsp;
            </span>
            <span class="title_text">
              {{productSale.name}}
            </span>
  
          </td>
          <td>{{productSale.total_order}}</td>
          <td>{{formatPrice(productSale.total_amount)}}</td>
          <td>{{ productSale.total_qty }}</td>
          <td>{{ formatPrice(productSale.item_total) }}</td>
                                 
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" style="text-align:center;">No data</td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="5">
            <div id="pagination" v-if="totalPages > 1">
              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="pageChange" />
              <ul class="showItems">
                <li>Show Items:
                  <select @change="recordChange" v-model="recordsPerPage">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="40">40</option>
                    <option :value="50">50</option>
                  </select>
                </li>
    
              </ul>
            </div>
          </td>
        </tr>
            
      </tfoot>
    </table>
  </div>
</template>
<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import Pagination from '@/pages/pagination/pagination.vue';
export default {
    props: ['productSales','loading','currency','totalPages', 'page','dataperPage'],
    mixins: [ReusableFunctionMixins],
    components: {
            Pagination,
          },
          data() {
                return {
                    recordsPerPage:10
                }
            },
            mounted:async function (){
                this.recordsPerPage = this.dataperPage
            },
            methods: {
                recordChange(){
                    this.$emit('record-changed', this.recordsPerPage)
                },
                pageChange(page){
                    this.$emit('page-changed', page)
                }
                
            },
    
}
</script>