<template>
  <div class="table_section">
    <table class="table  table-hover">
      <thead>
        <th width="30%">Store Name</th>
        <th>Accepted Orders</th>
        <th>Denied Orders</th>
        <th>Failed To post</th>                  
      </thead>
      <tbody v-if="storeorders.length > 0">
        <tr v-for="storeorder in storeorders" :key="storeorder.name">
          <td>
            <span class="title_text">
              {{storeorder.name}}
            </span>
      
          </td>
          <td>{{storeorder.posted_orders}}</td>
          <td>{{storeorder.denied_orders}}</td>
          <td>{{storeorder.notposted_orders}}</td>                         
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" style="text-align:center;">No data</td>
        </tr>
      </tbody>
  
      <tfoot>
        <tr>
          <td colspan="7">
            <div id="pagination" v-if="totalPages > 1">
              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="pageChange" />
              <ul class="showItems">
                <li>Show Items:
                  <select @change="recordChange" v-model="recordsPerPage">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="40">40</option>
                    <option :value="50">50</option>
                  </select>
                </li>
      
              </ul>
            </div>
          </td>
        </tr>
              
      </tfoot>
    </table>
  </div>
</template>
    <script>
    import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
    import Pagination from '@/pages/pagination/pagination.vue';
    export default {
        props: ['storeorders','loading','totalPages', 'page','dataperPage'],
        mixins: [ReusableFunctionMixins],
        components: {
              Pagination,
            },
            data() {
                  return {
                      recordsPerPage:10
                  }
              },
              mounted:async function (){
                  this.recordsPerPage = this.dataperPage
              },
              methods: {
                  recordChange(){
                      this.$emit('record-changed', this.recordsPerPage)
                  },
                  pageChange(page){
                      this.$emit('page-changed', page)
                  }
                  
              },
    }
    </script>