<template>
  <div class="table_section">
    <table class="table  table-hover">
      <thead>
        <th width="40%">Date</th>
        <th>Orders</th>
        <th>Total Sales (<b>{{ currency }}</b>)</th>
        <th>Sub Total (<b>{{ currency }}</b>)</th>
        <th>Avarage Order Value</th>                      
      </thead>
      <tbody v-if="dateSales.length > 0">
        <tr v-for="dateSale in dateSales" :key="dateSale.name">
          <td>
            
            <span class="title_text">
              {{dateSale.name}}
            </span>
    
          </td>
          <td>{{dateSale.total_order}}</td>
          <td>{{formatPrice(dateSale.total_amount)}}</td>
          <td>
            {{ formatPrice(dateSale.sub_total_price) }}
          </td>
          <!-- <td>
            {{ formatPrice(agentSale.total_shipping_cost) }}
          </td> -->
          <td>
            {{ dateSale.order_average_price }}
          </td>       
                                   
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" style="text-align:center;">No data</td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="5">
            <div id="pagination" v-if="totalPages > 1">
              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="pageChange" />
              <ul class="showItems">
                <li>Show Items:
                  <select @change="recordChange" v-model="recordsPerPage">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="40">40</option>
                    <option :value="50">50</option>
                  </select>
                </li>
    
              </ul>
            </div>
          </td>
        </tr>
            
      </tfoot>
    </table>
  </div>
</template>
  <script>
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  export default {
      props: ['dateSales','loading','currency','totalPages', 'page','dataperPage'],
      mixins: [ReusableFunctionMixins],
      components: {
            Pagination,
          },
          data() {
                return {
                    recordsPerPage:10
                }
            },
            mounted:async function (){
                this.recordsPerPage = this.dataperPage
            },
            methods: {
                recordChange(){
                    this.$emit('record-changed', this.recordsPerPage)
                },
                pageChange(page){
                    this.$emit('page-changed', page)
                }
                
            },
  }
  </script>