<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="pl-3r pr-2r pbt-1r">
          <div class="content_wrapper_box pop">
            <div class="wrapper_header p-sm">
              <div class="header_section">
                <div class="dash_header_text"><h2>Offer Summary</h2></div>
              </div>
  
            </div>
            <div class="wrapper_body">
              <div class="table_section">
                <table class="table  table-hover">
                  <thead>
                    <th width="40%">Offer Name</th>
                    <th>Orders</th>
                    <th>Sales (<b>{{ currency }}</b>)</th>          
                  </thead>
                  <tbody v-if="reportData.summarysales !=undefined && reportData.summarysales.length > 0">
                    <tr v-for="summartsale in reportData.summarysales" :key="summartsale.name">
                      <td>
                        <span class="title_text">
                          {{summartsale.offer_name}}
                        </span>
  
                      </td>
                      <td>{{summartsale.total_order}}</td>
                      <td>{{formatPrice(summartsale.total_amount)}}</td>        
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" style="text-align:center;">No data</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div><!-- popular items-->
                      
        </div><!-- End popular items-->
      </div>
      <div class="col-md-6">
        <div class="pl-3r pr-2r pbt-1r">
          <div class="content_wrapper_box pop">
            <div class="wrapper_header p-sm">
              <div class="header_section">

                <div class="dash_header_text"><h2>Offers Per Store</h2></div>
                <div class="more-link">
                  <a href="#" class="orange_link"
                    @click="redirectToDetailPage('offers_by_store')">View More</a>
                </div>
              </div>
  
            </div>
            <div class="wrapper_body">
              <div class="table_section">
                <table class="table  table-hover">
                  <thead>
                    <th  width="40%">Store </th>
                    <th>Offer Name</th>
                    <th>Order</th>
                    <th>Sales (<b>{{ currency }}</b>)</th>             
                  </thead>
                  <tbody v-if="reportData.store_sales !=undefined && reportData.store_sales.length > 0">
                    <tr v-for="storeoffersSale in reportData.store_sales" :key="storeoffersSale.name">
                      <td>
                        <span class="title_text">
                          {{storeoffersSale.name}}
                        </span>
                      </td>
                      <td>{{ storeoffersSale.offer_name }}</td>
                      <td>{{storeoffersSale.total_order}}</td>
                      <td>{{formatPrice(storeoffersSale.total_amount)}}</td>      
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" style="text-align:center;">No data</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div><!-- popular items-->
                      
        </div><!-- End popular items-->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="pl-3r pr-2r pbt-1r">
          <div class="content_wrapper_box pop">
            <div class="wrapper_header p-sm">
            
              <div class="header_section">
                <div class="dash_header_text"><h2>Products per offer</h2></div>
                <div class="more-link">
                  <a href="#" class="orange_link"
                    @click="redirectToDetailPage('offers_by_product')">View More</a>
                </div>
              </div>
  
            </div>
            <div class="wrapper_body">
              <div class="table_section">
                <table class="table  table-hover">
                  <thead>
                    <th width="25%" >Product</th>
                    <th width="25%">Offer Name</th>
                    <th>Orders</th>
                    <th>Order Sales</th>
                    <th>Items Sold</th>
                    <th>Item Sales</th>
                                
                  </thead>
                  <tbody v-if="reportData.product_sales != undefined && reportData.product_sales.length > 0">
                    <tr v-for="productSale in reportData.product_sales" :key="productSale.name">
                      <td>
                        <span class="title_text">
                          {{productSale.name}}
                        </span>
  
                      </td>
                      <td>
                        {{ productSale.offer_name }}
                      </td>
                      <td>{{productSale.total_order}}</td>
                      <td>{{formatPrice(productSale.total_amount)}}</td>
                      <td>{{ productSale.total_qty }}</td>
                      <td>{{ formatPrice(productSale.item_total) }}</td>
                                 
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" style="text-align:center;">No data</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div><!-- popular items-->
                      
        </div><!-- End popular items-->
      </div>
      <div class="col-md-6">
        <div class="pl-3r pr-2r pbt-1r">
          <div class="content_wrapper_box pop">
            <div class="wrapper_header p-sm">
        
              <div class="header_section">
                <div class="dash_header_text"><h2>Offers Per Channel</h2></div>
                <div class="more-link">
                  <a href="#" class="orange_link"
                    @click="redirectToDetailPage('offers_by_channel')">View More</a>
                </div>
              </div>
  
            </div>
            <div class="wrapper_body">
              <div class="table_section">
                <table class="table  table-hover">
                  <thead>
                    <th width="40%">Channel Name</th>
                    <th>Offer Name</th>
                    <th>Order</th>
                    <th>Sales (<b>{{ currency }}</b>)</th>                                
                  </thead>
                  <tbody v-if="reportData.channel_sales !=undefined && reportData.channel_sales.length > 0">
                    <tr v-for="channelSale in reportData.channel_sales" :key="channelSale.name">
                      <td>
                        <span class="title_text">
                          {{channelSale.name}}
                        </span>
  
                      </td>
                      <td>{{channelSale.offer_name}}</td>
                      <td>{{channelSale.total_order}}</td>
                      <td>{{formatPrice(channelSale.total_amount)}}</td>
                            
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4" style="text-align:center;">No data</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div><!-- popular items-->
                      
        </div><!-- End popular items-->
      </div>
    </div>
  </div>
</template>
<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
export default {
    props: [
        'currency',
        'loading', 
        'reportData'
    ],
    mixins: [ReusableFunctionMixins],
    methods: {
        redirectToDetailPage(report){
            this.$router.push({
                name: 'offer_details',
                params: {
                    type: report
                }
            });
          },
    },
}
</script>