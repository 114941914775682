<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="dash_card_body">
              <div class="header_section">
                <h4 class="menu_header">Analytics</h4>
                <div class="" v-if="user.role.id == 1 || user.role.id == 2 || user.role.id ==6">
                  <div class="btn-toolbar">
                    <button class="btn btn-default custom_btn" @click="exportOrder()" v-if="report !='offers'">
                      <span v-if="exporting"><i class="fa fa-spinner fa-spin"></i></span> <span v-else><i class="fas fa-file-export"></i> </span> Excel
                    </button>
                  </div>
                </div>
              </div>

              <div class="search_section">
                <div class="row">
                  <div class="col-md-10 col-sm-12">
                    <div class="form-group mt-1">
                      <select v-model="report" @change="filterReport()" class="form-control">
                        <option value="">--Select Report --</option>
                        <option value="salesByDate">Sales By Date</option>
                        <option value="salesByHour">Sales By Hour</option>
                        <option value="salesByAgent">Sales By Agent</option>
                        <option value="salesByProduct">Sales By Product</option>
                        <option value="salesByStore">Sales By Store</option>
                        <option value="salesByPaymentOption">Sales By Payment Option</option>
                        <option value="salesByChannel"> Sales By Channel</option>
                        <option value="salesByCategory">Sales By Category</option>
                        <option value="toplocations">Top Locations</option>
                        <option value="topcustomers">Top Customers</option>
                        <option value="offers">Offers Report</option>
                        <option value="orderPosStatus">Order Pos Status</option>
                        <option v-if="user.role.id == 1" value="merchants">Merchants Report</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <button class="btn btn_reserveport_orange mt-1" @click="filterReport()"><span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span> search</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <Datepicker placeholder="Start Date" v-model="startDate" utc autoApply :format="customFormatter"></Datepicker>
                  </div>
                  <div class="col-md-2">
                    <Datepicker placeholder="End Date" v-model="endDate" utc autoApply :format="customFormatter"></Datepicker>
                  </div>
                  <div class="col-md-2">
                    <select class="form-control" v-model="searchChannel" >
                      <option value="">--Select Channel--</option>
                      <option value="FOODIE">FOODIE</option>
                      <option v-for="channel in channels" :key="channel.id" :value="channel.channel_name">
                        {{channel.channel_name}}</option>
                    </select>
                  </div>
                  <div class="col-md-2" v-if="report !='merchants'">
                    <Multiselect v-model="location" :close-on-select="true" :options="strsoptions" :custom-label="name" :searchable="true" :placeholder="'Select  Store'" label="name" track-by="name"></Multiselect>
                  </div>
                </div>
              </div>

            </div>
            <div class="dash_card_body">
              <div class="summary_body">
                <!-- orders Report -->
                <div class="row" v-if="report !='offers'">
                  <div v-if="report !='toplocations' && report !='topcustomers' && report !='orderPosStatus'" class="col-md-12 col-sm-12 mb-5">
                    <div class="cardbox-graph card h-100">
                      <div class="card-body  " v-if="reportsummaries.length > 0">
                        <GChart :type="graphtype" :data="ChartData" :options="COptions" />
                      </div>
                      <div v-else class="card-body"  style="text-align:center;">
                        No Data
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="cardbox card h-100">
                      <h4>{{rptitle}} </h4>
                      <div class="">
                        <div v-if="report =='salesByProduct'">
                          <ProductTable 
                            v-bind:productSales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></ProductTable>
                          <!-- <product-table/> -->
                        </div>
                        <div v-else-if="report =='salesByDate'">
                          <DateSalesTable 
                            v-bind:dateSales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></DateSalesTable>
                        </div>
                        <div v-else-if="report =='salesByHour'">
                          <HourSaleTable 
                            v-bind:hourSales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></HourSaleTable>
                        </div>

                        <div v-else-if="report =='salesByStore'">
                          <StoreSaleTable 
                            v-bind:storeSales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></StoreSaleTable>
                        </div>

                        <div v-else-if="report =='orderPosStatus'">
                          <OrderStatusTable 
                            v-bind:storeorders="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></OrderStatusTable>
                        </div>

                        <div v-else-if="report =='salesByChannel'">
                          <ChannelSaleTable 
                            v-bind:channelSales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></ChannelSaleTable>
                        </div>
                        <div v-else-if="report =='salesByCategory'">
                          <CategorySalesTable 
                            v-bind:categorySales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></CategorySalesTable>
                        </div>
                        
                        <div v-else-if="report =='toplocations'">
                          <ToplocationSaleTable 
                            v-bind:locationSales="reportsummaries"  
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></ToplocationSaleTable>
                        </div>
                        <div v-else-if="report =='topcustomers'">
                          <CustomerSaleTable 
                            v-bind:customerSales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></CustomerSaleTable>
                        </div>
                        <div v-else-if="report =='salesByAgent'">
                          <AgentSalesTable 
                            v-bind:agentSales="reportsummaries" 
                            v-bind:loading="loading" 
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></AgentSalesTable>
                        </div>                        
                      </div>
                    </div>
                  </div>

                </div>
                <!-- offers section -->
                <div v-else-if="report =='offers'">
                  <offersReport v-bind:loading ="loading" 
                    v-bind:currency ="pesapalConfig.currency_code"  
                    v-bind:reportData="offerReportData"
                  ></offersReport>
                </div>
                <!-- end -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ProductTable from '@/pages/admin/dashboard/reportDetails/productTable.vue'
import AgentSalesTable from '@/pages/admin/dashboard/reportDetails/agentSalesTable.vue'
import DateSalesTable from '@/pages/admin/dashboard/reportDetails/dateSalesTable.vue'
import HourSaleTable from '@/pages/admin/dashboard/reportDetails/hoursalesTable.vue'
import StoreSaleTable from '@/pages/admin/dashboard/reportDetails/storeSaleTable.vue'
import ChannelSaleTable from '@/pages/admin/dashboard/reportDetails/channelSaleTable.vue';
import  CustomerSaleTable from '@/pages/admin/dashboard/reportDetails/topcustomersSalesTable.vue'
import ToplocationSaleTable from '@/pages/admin/dashboard/reportDetails/toplocationsSalesTable.vue'
import CategorySalesTable from '@/pages/admin/dashboard/reportDetails/categoryTable.vue';
import OrderStatusTable from '@/pages/admin/dashboard/reportDetails/orderPosReport.vue'
import offersReport from '@/pages/admin/dashboard/reportDetails/offersReport.vue'
import { GChart } from 'vue-google-charts';
import Multiselect from '@vueform/multiselect';

export default {
    components: {
        Multiselect,
        GChart,
        ProductTable,
        AgentSalesTable,
        DateSalesTable,
        HourSaleTable,
        StoreSaleTable,
        ChannelSaleTable,
        CustomerSaleTable,
        ToplocationSaleTable,
        CategorySalesTable,
        offersReport,
        OrderStatusTable
    },
    data() {
        return {
            searchChannel: '',
            channels: [],
            export: '',
            spinner: false,
            title: '',
            loading: true,
            channelSales: [],

            page:1,
            totalPages: 0,
            totalRecords: 0,
            dataperPage: 10,

        
            startDate: this.$store.state.ordReportstartDate,
            endDate: this.$store.state.ordReportendDate,
            location: this.$store.state.ordReportlocation,
            stores: [],
            limit: 10,
            report: this.$route.params.type,
            
            ChartData: [],
            COptions: [],
            rptitle: "",
            tabletitle: "Name",
            tablevaluecol: "Total",
            graphtype: "",
            reportsummaries: [],
            BarchartOptions: {
                height: 600
            },
            ChartOptions: {
                height: 400,
            },
            recordsPerPage: 10,
            agentSalesChartData: [
                ['Agent', 'Sales']
            ],
            hourSalesChartData: [
                ['Hour', 'Sales']
            ],
            dateSalesChartData: [
                ['Date', 'Sales']
            ],
            paymentOptionsChartData: [
                ['Payment Option', 'Sales']
            ],
            productsChartData: [
                ['Product', 'Sales']
            ],
            deliveryOptionChartData: [
                ['Delivery Method', 'Sales']
            ],
            categoriesChartData: [
                ['Category', 'Sales']
            ],
            storeSalesChartData: [
                ['Store', 'Sales']
            ],
            channelSalesChartData: [
                ['Channel', 'Sales']
            ],
            AgentOnlineChartData: [
                ['Online', 'Agent']
            ],
            strsoptions: [{ "value": '', "name": 'All Stores' }],
            product: '',
            productOptions: [{
                "value": '',
                "name": 'All Products'
            }],
            merchant: '',
            merchantReportLoading:false,
            exporting:false,
            offerReportData:[],
        }
    },
    mounted: async function () {
        this.getReportDetails()
        this.getBranches();
        this.getItems();
        this.getChannels();
        this.getOffersData()
    },
    methods: {
     
        filterReport() {
          this.page =1
            this.assingDataToChats()
            if (this.report != '') {
                if(this.report =='merchants'){
                  this.$router.push({
                    path:"merchants-report"
                  })
                }  else {
                  this.$router.push({
                      name: 'dashboard_details',
                      params: {
                          type: this.report
                      }
                  });
                } 
              }
              else {
                this.$router.push({
                    name: 'analytics',
                });

            } 
            this.getReportDetails();
            this.getOffersData();
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },

        async exportOrder() {
            this.exporting = true;
            try {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                let url = `${this.dashBaseUrl}/orders-report-detail/${this.merchantID}`;
                url = new URL(url);
                url.searchParams.set('page', this.page);
                url.searchParams.set('size', this.dataperPage);
                if (this.startDate !== '') {
                    url.searchParams.set('start_date', this.startDate);
                }
                if (this.endDate !== '') {
                    url.searchParams.set('end_date', this.endDate);
                }
                if (this.location !== '') {
                    url.searchParams.set('store', this.location);
                }
                if (this.product !== '') {
                    url.searchParams.set('product', this.product);
                }
                if(this.searchChannel !==''){
                  url.searchParams.set('channel',this.searchChannel);
                }
                url.searchParams.set('is_export', 1);
                let colname = 'Name'
                const colvalue = 'Total'
                const colsubtotal ='Sub Total'
                const response = await this.axios.get(url, config);
                if (response.status == 200) {
                    this.exporting = false;
                    let exportDAta = '';
                    let fileName = '';
                    if (this.report == 'salesByDate') {
                        colname = 'Date';
                        fileName = Date.now() + '_datesales';
                        exportDAta = response.data.data.datesales;
                    } else if (this.report == 'salesByHour') {
                        colname = 'Hour';
                        fileName = Date.now() + '_hoursales';
                        exportDAta = response.data.data.hoursales;
                    } else if (this.report == 'salesByAgent') {
                        colname = 'Agent Name';
                        fileName = Date.now() + '_agentsales';
                        exportDAta = response.data.data.agentsales;
                    } else if (this.report == 'salesByProduct') {
                        fileName = Date.now() + '_productsales';
                        exportDAta = response.data.data.productsales;
                    } else if (this.report == 'salesByCategory') {
                        fileName = Date.now() + '_categorysales';
                        exportDAta = response.data.data.categorysales;
                    } else if (this.report == 'toplocations') {
                        colname = 'Location';
                        fileName = Date.now() + '_locationsales';
                        exportDAta = response.data.data.locationsales;
                    } else if (this.report == 'topcustomers') {
                        fileName = Date.now() + '_customersales';
                        exportDAta = response.data.data.customersales;
                    } else if (this.report == 'salesByStore') {
                        fileName = Date.now() + '_storesales';
                        exportDAta = response.data.data.storesales;
                    } else if (this.report == 'salesByChannel') {
                        colname = 'Channel';
                        fileName = Date.now() + '_channelsales';
                        exportDAta = response.data.data.channelsales;
                    } else if (this.report == 'salesByPaymentOption') {
                        colname = 'Payment Method';
                        fileName = Date.now() + '_paymentOption';
                        exportDAta = response.data.data.paymentsales;
                    } else if (this.report == 'salesByDeliveryOption') {
                        colname = 'Delivery Method';
                        fileName = Date.now() + '_deliveryoptionsales';
                        exportDAta = response.data.data.deliverysales;
                    }

                    const reportData = [];
                    for (const data of Object.values(exportDAta)) {
                        const dataObj = {}
                        dataObj[colname] = data.name;
                        if(this.report =='topcustomers'){
                          dataObj["Phone"] = data.phone;
                          dataObj["Email"] = data.email;
                          dataObj["channel"] = data.channel_name
                        }
                        dataObj["Orders"] = data.total_order;
                        dataObj[colvalue] = this.formatPrice(data.total_amount)
                        if(this.report !='topcustomers' && this.report !='salesByCategory' && this.report !='salesByStore'){
                          dataObj[colsubtotal] = this.formatPrice(data.sub_total_price)
                        }
                        if(this.report == 'salesByChannel' && 
                        this.report !='topcustomers' && 
                        this.report !='salesByStore' && this.report !='salesByProduct'){
                          dataObj["Shipping Fee"] = this.formatPrice(data.total_shipping_cost)
                        }
                        dataObj["Avarage Order Value"] = this.formatPrice(data.order_average_price)
                        if(this.report == 'salesByProduct'){
                          dataObj['Items sold'] = data.total_qty
                          dataObj['Items Sales'] = this.formatPrice(data.item_total)
                        }
                        reportData.push(dataObj);
                    }
                    this.exportExcel(reportData, fileName)
                    // AgentOnlineChartData
                }
            } catch (error) {
                this.exporting = false;
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }
            // 
        },
        async getReportDetails() {
            this.loading = true;
            const LoaderCover = document.getElementById('main-loading-cover');
            LoaderCover.style.opacity = .4;
            const loader = document.getElementById('main-loader');
            loader.style.display = 'block';
            const payload = {
                'start_date': this.startDate,
                'end_date': this.endDate,
                'location': this.location,
            }
            this.$store.commit('updateOReportSearchData', payload)
            try {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                let url = `${this.dashBaseUrl}/orders-report-detail/${this.merchantID}`;
                url = new URL(url);
                url.searchParams.set('page', this.page);
                url.searchParams.set('size', this.dataperPage);
                if (this.startDate !== '') {
                    url.searchParams.set('start_date', this.startDate);
                }
                if (this.endDate !== '') {
                    url.searchParams.set('end_date', this.endDate);
                }
                if (this.location  != undefined && this.location !== '') {
                    url.searchParams.set('store', this.location);
                }
                if (this.product !== '') {
                    url.searchParams.set('product', this.product);
                }
                if(this.searchChannel !==''){
                  url.searchParams.set('channel',this.searchChannel);
                }
                const response = await this.axios.get(url, config);
                if (response.status == 200) {
                  console.log("data",response.data.data)
                    this.loading = false;
                    const datesales = response.data.data.datesales.data;
                    const hoursales = response.data.data.hoursales.data;
                    const productsales = response.data.data.productsales.data;
                    const storesales = response.data.data.storesales.data;
                    const categorysales = response.data.data.categorysales.data;
                    const paymentsales = response.data.data.paymentsales.data;
                    const customersales = response.data.data.customersales.data;
                    const locationsales = response.data.data.locationsales.data;
                    const channelsales = response.data.data.channelsales.data;
                    const agentsales = response.data.data.agentsales.data;
                    const deliverysales = []; // response.data.data.deliverysales.data;

                    // construct products chart
                    for (const product of Object.values(productsales)) {
                        const productsalesArr = [product.name, product.item_total];
                        this.productsChartData.push(productsalesArr);
                    }
                    // construct date sale chart 
                    for (const datesale of Object.values(datesales)) {
                        const datesalesArr = [datesale.name, datesale.total_amount]
                        this.dateSalesChartData.push(datesalesArr)
                    }
                    // construct hour sales chart
                    for (const hoursale of Object.values(hoursales)) {
                        const hoursalesArr = [hoursale.name + ":00", hoursale.total_amount];
                        this.hourSalesChartData.push(hoursalesArr);
                    }
                    // construct store sale chart
                    for (const storesale of Object.values(storesales)) {
                        const storeArray = [storesale.name, storesale.total_amount]
                        this.storeSalesChartData.push(storeArray)
                    }
                    // construct agent sales chart
                    for (const agentsale of Object.values(agentsales)) {
                        const agentsalesArr = [agentsale.name, agentsale.total_amount]
                        this.agentSalesChartData.push(agentsalesArr)
                    }
                    // construct chart for categories
                    for (const categorysale of Object.values(categorysales)) {
                        const categorysalesArray = [categorysale.name, categorysale.total_amount];
                        this.categoriesChartData.push(categorysalesArray);
                    }
                    // construct chart for payment options
                    for (const payment of Object.values(paymentsales)) {
                        const paymentsalesArr = [payment.name, payment.total_amount]
                        this.paymentOptionsChartData.push(paymentsalesArr);
                    }
                    // construct charts for delivery option
                    for (const delivery of Object.values(deliverysales)) {
                        const deliverysalesArr = [delivery.name, delivery.total_amount];
                        this.deliveryOptionChartData.push(deliverysalesArr);
                    }
                    // construct chart for channels
                    for (const channel of Object.values(channelsales)) {
                        const channelsalesArr = [channel.name, channel.total_amount];
                        this.channelSalesChartData.push(channelsalesArr)
                    }

                    if (this.report == 'salesByDate') {
                        this.reportsummaries = datesales;
                        this.rptitle = "Sales By Date";
                        this.tabletitle = "Date";
                        this.tablevaluecol = "Total";
                        this.graphtype = "LineChart";
                        this.ChartData = this.dateSalesChartData;
                        this.COptions = this.ChartOptions;

                        this.totalPages = Math.ceil(response.data.data.datesales.total / this.dataperPage);
                        this.totalRecords = response.data.data.datesales.total;
                    } else if (this.report == 'salesByHour') {
                        this.reportsummaries = hoursales;
                        this.rptitle = "Sales By Hour";
                        this.tabletitle = "Hour";
                        this.tablevaluecol = "Total";
                        this.graphtype = "LineChart";
                        this.ChartData = this.hourSalesChartData;
                        this.COptions = this.ChartOptions;

                        this.totalPages = Math.ceil(response.data.data.hoursales.total / this.dataperPage);
                        this.totalRecords = response.data.data.hoursales.total;
                    } else if (this.report == 'salesByAgent') {
                        this.reportsummaries = agentsales;
                        this.rptitle = "Sales By Agent";
                        this.tabletitle = "Agent Name";
                        this.tablevaluecol = "Total";
                        this.graphtype = "BarChart";
                        this.ChartData = this.agentSalesChartData;
                        this.COptions = this.BarchartOptions;

                        this.totalPages = Math.ceil(response.data.data.agentsales.total / this.dataperPage);
                        this.totalRecords = response.data.data.agentsales.total;
                    } else if (this.report == 'salesByProduct') {
                        this.reportsummaries = productsales;
                        this.rptitle = "Sales By Products";
                        this.tabletitle = "Name";
                        this.tablevaluecol = "Total";
                        this.graphtype = "BarChart";
                        this.ChartData = this.productsChartData;
                        this.COptions = this.BarchartOptions;

                        this.totalPages = Math.ceil(response.data.data.productsales.total / this.dataperPage);
                        this.totalRecords = response.data.data.productsales.total;
                    } else if (this.report == 'salesByCategory') {
                        this.reportsummaries = categorysales;
                        this.rptitle = "Sales By Categories";
                        this.tabletitle = "Name";
                        this.tablevaluecol = "Total";
                        this.graphtype = "BarChart";
                        this.ChartData = this.categoriesChartData;
                        this.COptions = this.BarchartOptions;

                        this.totalPages = Math.ceil(response.data.data.categorysales.total / this.dataperPage);
                        this.totalRecords = response.data.data.categorysales.total;
                    } else if (this.report == 'toplocations') {
                        this.reportsummaries = locationsales;
                        this.rptitle = "Top Location";
                        this.tabletitle = "Location";
                        this.tablevaluecol = "Total";

                        this.totalPages = Math.ceil(response.data.data.locationsales.total / this.dataperPage);
                        this.totalRecords = response.data.data.locationsales.total;
                    } else if (this.report == 'topcustomers') {
                        this.reportsummaries = customersales;
                        this.rptitle = "Top Customers";
                        this.tabletitle = "Customer";
                        this.tablevaluecol = "Total";

                        this.totalPages = Math.ceil(response.data.data.customersales.total / this.dataperPage);
                        this.totalRecords = response.data.data.customersales.total;
                    } else if (this.report == 'salesByStore') {
                        this.reportsummaries = storesales;
                        this.rptitle = "Sales By Store";
                        this.tabletitle = "Store Name";
                        this.tablevaluecol = "Total";
                        this.graphtype = "PieChart";
                        this.ChartData = this.storeSalesChartData;
                        this.COptions = this.ChartOptions;

                        this.totalPages = Math.ceil(response.data.data.storesales.total / this.dataperPage);
                        this.totalRecords = response.data.data.storesales.total;

                    } else if (this.report == 'salesByChannel') {
                        this.reportsummaries = channelsales;
                        this.rptitle = "Sales By Channel";
                        this.tabletitle = "Channel";
                        this.tablevaluecol = "Total";
                        this.graphtype = "PieChart";
                        this.ChartData = this.channelSalesChartData;
                        this.COptions = this.ChartOptions;

                        this.totalPages = Math.ceil(response.data.data.channelsales.total / this.dataperPage);
                        this.totalRecords = response.data.data.channelsales.total;
                    } else if (this.report == 'salesByPaymentOption') {
                        this.reportsummaries = paymentsales;
                        this.rptitle = "Sales By Payment Option";
                        this.tabletitle = "Payment Method";
                        this.tablevaluecol = "Total";
                        this.graphtype = "PieChart";
                        this.ChartData = this.paymentOptionsChartData;
                        this.COptions = this.ChartOptions;

                        this.totalPages = Math.ceil(response.data.data.paymentsales.total / this.dataperPage);
                        this.totalRecords = response.data.data.paymentsales.total;

                    } else if (this.report == 'salesByDeliveryOption') {

                        this.reportsummaries = deliverysales;
                        this.rptitle = "Sales By Delivery Option";
                        this.tabletitle = "Delivery Method";
                        this.tablevaluecol = "Total";
                        this.graphtype = "PieChart";
                        this.ChartData = this.deliveryOptionChartData;
                        this.COptions = this.ChartOptions;

                        this.totalPages = Math.ceil(response.data.data.deliverysales.total / this.dataperPage);
                        this.totalRecords = response.data.data.deliverysales.total;
                    }else if (this.report == 'orderPosStatus'){
                      this.rptitle = "Order Pos Status By Store"
                      this.reportsummaries = response.data.data.order_pos_status.data
                      this.totalPages = Math.ceil(response.data.data.order_pos_status.total / this.dataperPage);
                      this.totalRecords = response.data.data.order_pos_status.total;
                    }
                    // AgentOnlineChartData
                    LoaderCover.style.opacity = 1;
                    loader.style.display = 'none';
                }
            } catch (error) {
                this.loading = false;
                LoaderCover.style.opacity = 1;
                loader.style.display = 'none';
                // if (error.response.status === 401) {
                //     this.$router.push({
                //         name: 'login'
                //     });
                // }
            }
        },
        async getOffersData(){
            // this.offerSpinner = true
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/offers_summary/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('limit', 5);
            
            if (this.startDate !== '') {
                url.searchParams.set('start_date', this.startDate);
            }
            if (this.endDate !== '') {
                url.searchParams.set('end_date', this.endDate);
            }
            if (this.location !=undefined && this.location !== '') {
                url.searchParams.set('store', this.location);
            }
            if(this.searchChannel !==''){
              url.searchParams.set('channel',this.searchChannel);
            }
            try{
                const response = await this.axios.get(url,config)
                if(response.status === 200){
                  this.offerReportData = response.data.data
                    
                    // this.offerSpinner = false
                    // this.offerSummary = response.data.data.summarysales;
                    // this.productOffersSales = response.data.data.product_sales;
                    // this.storeoffersSales = response.data.data.store_sales;
                    // this.channelSales = response.data.data.channel_sales;
               }
            }catch(err){
                // 
                // this.offerSpinner = false
            }
        },
       
        
        async getItems() {
            let url = `${this.baseUrl}//menu_item`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                const menuItems = response.data.data
                for (let x = 0; x < menuItems.length; x++) {
                    this.productOptions.push({
                        "value": menuItems[x].menu_item.id,
                        "name": menuItems[x].menu_item.item_name
                    })

                }
                // this.productOptions
            }).catch((err) => {
                const message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
                console.warn("error", message);
            })
        },

        async getBranches() {
            const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.stores = stores;
                        for (let i = 0; i < stores.length; i++) {
                            this.strsoptions.push({
                                "value": stores[i].id,
                                "name": stores[i].branch_name
                            })
                        }
                    }
                }
            }).catch((err) => {
                console.log('error', err);
            })
        },
        async getChannels() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.channels = response.data.data
                }
            });
        },
        assingDataToChats(){
          this.agentSalesChartData = [
                ['Agent', 'Sales']
            ];
            this.hourSalesChartData = [
                ['Hour', 'Sales']
            ];
            this.dateSalesChartData = [
                ['Date', 'Sales']
            ];
            this.productsChartData = [
                ['Product', 'Sales']
            ];
            this.storeSalesChartData = [
                ['Store', 'Sales']
            ];
            this.categoriesChartData = [
                ['Category', 'Sales']
            ];
            this.paymentOptionsChartData = [
                ['Payment Option', 'Sales']
            ];
            this.deliveryOptionChartData = [
                ['Delivery Method', 'Sales']
            ];
            this.channelSalesChartData = [
                ['Channel', 'Sales']
            ];
        },
        onPageChange(page) {
            this.assingDataToChats()
            this.page = page
            this.getReportDetails();
        },
        onChangeRecordsPerPage(recordSize) {
          this.dataperPage = recordSize
            this.assingDataToChats()
            this.getReportDetails();
            // ds
        },

    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
